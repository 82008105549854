import React from "react";
import AudioPlayer from 'react-h5-audio-player'
import '../assets/sass/podcast_player.scss'

const Podcast = ({src}) => {

  return (
    <div>
      <AudioPlayer src={src} defaultDuration="Loading" />
    </div>
  )
}

export default Podcast