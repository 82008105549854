import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout.js";
import EpisodeStub from "../components/episodeStub.js";
import Podcast from "../components/podcast.js";

function explicitTitle(explicit, title) {
  if(explicit === "yes"){
    return title + ' 🅴'
  }
  return title
}

const EpisodeTemplate = ({ data }) => {
  const episode = data.episode
  const next = data.nextEp
  const prev = data.prevEp
  let title = explicitTitle(episode.itunes.explicit, episode.title)

  return (
    <Layout>
      <body class="is-preload">
          <div id="page-wrapper">
              <section id="wrapper">
                <header>
                  <div class="inner">
                    <h2>{title}</h2>
                    <p>{episode.isoDate}</p>
                  </div>
                  <div class="inner">
                    <Podcast src={episode.enclosure.url}/>
                  </div>
                </header>
                
                <section class="wrapper spotlight style4">
                  <div class="inner">
                    <div class="content">
                      <h3 class="major">Description</h3>
                      <div dangerouslySetInnerHTML={{__html: episode.content.encoded}} />
                    </div>
                  </div>
                </section>

                <section class="wrapper alt">
                  <div class="inner">
                    <h3 class="major">Other Episodes</h3>

                    <section class="features">
                      <EpisodeStub ep={prev}/>
                      <EpisodeStub ep={next}/>
                    </section>
                  </div>
                </section>
              </section>
          </div>
      </body>
    </Layout>
  )
}

export const query = graphql`
query GetEpisode($id: String!, $next: String, $prev: String){
  episode: feedOverMangaCast(id: {eq: $id}) {
    id
    title
    isoDate(formatString: "MMMM Do, YYYY")
    itunes {
      explicit
      season
      episode
    }
    enclosure {
      url
      type
      length
    }
    content {
      encoded
    }
  }
  nextEp: feedOverMangaCast(id: {eq: $next}){
		...episodeStub
  }
  prevEp: feedOverMangaCast(id: {eq: $prev}){
		...episodeStub
  }
}

fragment episodeStub on FeedOverMangaCast {
  title
  contentSnippet
  itunes {
      explicit
      season
      episode
  }
}
`

export default EpisodeTemplate